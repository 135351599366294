<div class="subscription-item" [ngClass]="{ active: subscription.status === 'active' || subscription.status === 'trialing' }">
  <div class="subscription-header">
    <h3>{{ subscription.plan.name }}</h3>

    <div class="subscription-status">
      <span
        class="status-badge"
        [ngClass]="{
          active: subscription.status === 'active' || subscription.status === 'trialing',
          inactive: subscription.status === 'canceled'
        }"
      >
        {{ subscription.status === 'canceled' ? ('SUBSCRIPTIONS.MY_SUBSCRIPTIONS.INACTIVE' | translate) : ('SUBSCRIPTIONS.MY_SUBSCRIPTIONS.ACTIVE' | translate) }}
      </span>

      <div class="renewal-badge" [ngClass]="subscription.status === 'canceled' ? 'inactive' : 'active'">
        <img [src]="subscription.status === 'canceled' ? '/assets/images/icons/xmark-solid-red.svg' : '/assets/images/icons/arrow-rotate-right.svg'" alt="status-icon" width="12" height="12" />
        {{ subscription.status === 'canceled' ? ('SUBSCRIPTIONS.MY_SUBSCRIPTIONS.CANCELED' | translate) : ('SUBSCRIPTIONS.MY_SUBSCRIPTIONS.RENEWS' | translate) }}
      </div>

      <a class="gateway-badge" (click)="handlePaymentMethodClick()">
        <img [src]="platformIcon" [alt]="platformName" width="16" height="16" />
        {{ platformName }}
        <img src="/assets/images/icons/arrow-action.svg" alt="link" width="16" height="16" />
      </a>
    </div>
  </div>
</div>
