import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { PaymentPlatform, PLATFORM_ICONS, PLATFORM_NAMES } from 'src/app/shared/constants/payment-platform.constants'

@Component({
  selector: 'app-subscription-item',
  templateUrl: './subscription-item.component.html',
  styleUrls: ['./subscription-item.component.scss'],
})
export class SubscriptionItemComponent implements OnInit {
  @Input() subscription: any
  @Output() onClickPaymentMethod = new EventEmitter<string>()

  public paymentMethod
  platformName: string;
  platformIcon: string;

  ngOnInit(): void {
    this.paymentMethod = this.subscription.gateway
    this.platformName = this.getPlatformName();
    this.platformIcon = this.getPlatformIcon();
  }

  handlePaymentMethodClick(): void {
    this.onClickPaymentMethod.emit(this.paymentMethod)
  }

  getPlatformIcon(): string {
    return PLATFORM_ICONS[this.paymentMethod] || PLATFORM_ICONS[PaymentPlatform.STRIPE]
  }

  getPlatformName(): string {
    return PLATFORM_NAMES[this.paymentMethod] || this.paymentMethod
  }
}
