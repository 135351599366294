export enum PaymentPlatform {
  APPLE = 'apple',
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  ANDROID = 'android',
  ADMIN = 'admin',
}

export const PLATFORM_ICONS: { [key: string]: string } = {
  [PaymentPlatform.APPLE]: '/assets/images/icons/apple-logo-black.svg',
  [PaymentPlatform.STRIPE]: '/assets/images/icons/credit-card-solid.svg',
  [PaymentPlatform.PAYPAL]: '/assets/images/icons/paypal.svg',
  [PaymentPlatform.ANDROID]: '/assets/images/icons/google-play.svg',
  [PaymentPlatform.ADMIN]: '/assets/images/icons/my-profile-user.svg',
}

export const PLATFORM_NAMES: { [key: string]: string } = {
  [PaymentPlatform.APPLE]: 'App Store',
  [PaymentPlatform.STRIPE]: 'Web/Stripe',
  [PaymentPlatform.PAYPAL]: 'PayPal',
  [PaymentPlatform.ANDROID]: 'Google Play',
  [PaymentPlatform.ADMIN]: 'Admin',
}

export const SUBSCRIPTION_DETAIL_URLS: { [key: string]: string } = {
  [PaymentPlatform.APPLE]: 'https://support.apple.com/en-us/118428',
  [PaymentPlatform.STRIPE]: 'https://billing.stripe.com/p/login/aEUbMgc4W4UL4py5kk',
  [PaymentPlatform.PAYPAL]: 'https://help.dinolingo.com/article/534-canceling-paypal',
  [PaymentPlatform.ANDROID]: 'https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en',
  [PaymentPlatform.ADMIN]: 'https://www.dinolingo.com/manage-subscription-billing/',
}
